<template>
  <div class="tw-flex tw-flex-col tw-px-2 tw-font-sans">
    <div v-if="isLoading" class="tw-flex tw-justify-center tw-items-center tw-absolute tw-inset-0 tw-z-20">
      <ui-loading class="tw-text-8xl tw-opacity-100"></ui-loading>
    </div>
    <div class="tw-flex tw-w-1/2">
      <div class="tw-w-full tw-flex tw-flex-col tw-gap-x-4 tw-gap-y-2 tw-bg-white">
        <div class="tw-flex tw-gap-4">
          <div class="tw-text-sm tw-font-medium tw-text-gray-500">Total: {{ stats.total }}</div>
          <div class="tw-text-sm tw-text-gray-500">{{ stats.per }}</div>
        </div>
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-flex-col tw-gap-1">
            <div class="tw-text-2xl tw-font-medium tw-tracking-tight tw-text-gray-900">
              {{ sym() }} {{ formatCurrency(stats.left) }}
            </div>
            <div class="tw-text-xs tw-text-gray-500">
              {{ stats.leftLabel }}
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-1">
            <div class="tw-text-2xl tw-font-medium tw-tracking-tight tw-text-gray-900">
              {{ sym() }} {{ formatCurrency(stats.right) }}
            </div>
            <div class="tw-text-xs tw-text-gray-500">
              {{ stats.rightLabel }}
            </div>
          </div>
        </div>
        <div class="tw-flex tw-w-full tw-h-6">
          <div class="tw-rounded-l-md tw-shadow" :class="[stats.leftColor]" :style="`width: ${stats.leftRatio}%`"></div>
          <div
            class="tw-rounded-r-md tw-shadow"
            :class="[stats.rightColor]"
            :style="`width: ${stats.rightRatio}%`"
          ></div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-w-full tw-pb-4 tw-gap-4">
      <div>
        <ui-select2
          label="Batch Actions"
          :items="['Create', 'Edit', 'Delete']"
          value=""
          :disabled="!!isLoading"
        ></ui-select2>
      </div>
      <div class="tw-w-48">
        <ui-select2
          label="Status"
          :items="statusFilters"
          keyField="id"
          labelField="name"
          searchable
          v-model="searchStatus"
          @input="(status) => setStatus(status)"
          :disabled="!!isLoading"
        ></ui-select2>
      </div>
      <div>
        <ui-date-preset label="Date" @input="onDateChange" />
      </div>
      <div class="tw-flex tw-flex-grow tw-justify-end tw-pt-6">
        <ui-button color="primary" :disabled="!!isLoading">Create invoice</ui-button>
      </div>
    </div>
    <div class="tw-w-full tw-rounded-md tw-bg-white tw-mt-2 tw-py-1 tw-border tw-flex-grow tw-overflow-hidden">
      <ui-data-table
        className=" tw-font-normal tw-h-full"
        tbodyClassName="tw-divide-double tw-divide-gray-300 tw-divide-y tw-text-gray-500"
        :headers="headers"
        :isLoading="!!isLoading"
        :showLoadingIcon="false"
        :items="invoices"
        resizeable-columns
        no-data-message="There are no items to display."
        fixed-table
        ><template #td-date="{ item }">
          <td class="tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700">
            {{ moment(item.date).format('MM-DD-YYYY') }}
          </td></template
        >
        <template #td-reference="{ item }">
          <td class="tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700">
            {{ item.invoiceNumber.substring(0, 10) }}
          </td></template
        >
        <template #td-status="{ item }">
          <td class="tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700">
            <a href="#">{{ mapStatus(item.invoiceStatus) }}</a>
          </td></template
        >
        <template #td-dueDate="{ item }"
          ><td class="tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700">
            {{ moment(item.dueDate).format('MM-DD-YYYY') }}
          </td></template
        >
        <!--            <td>-->
        <!--              <v-icon color="green">done</v-icon>-->
        <!--              &lt;!&ndash;              <v-icon v-else>close</v-icon>&ndash;&gt;-->
        <!--            </td>-->
        <template #td-source="{}">
          <td class="tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700">
            <img src="request.png" style="height: 20px" />
          </td>
        </template>
        <template #td-contact="{ item }"
          ><td class="tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700">
            <span v-if="item.contactName">
              {{ item.contactName }}
              (
              <span v-if="item.contactId" class="tw-text-success-300">{{ item.systemContactName }}</span>
              <a href="#" class="tw-text-primary-300 hover:tw-underline visited:tw-text-tertiary-400" v-else>Match</a>
              )
            </span>
            <span v-else>
              <a href="#" class="tw-text-primary-300 hover:tw-underline visited:tw-text-tertiary-400">Select Contact</a>
            </span>
          </td>
        </template>
        <template #td-amount="{ item }">
          <td class="tw-text-right tw-whitespace-nowrap tw-px-2 tw-py-2 tw-text-sm tw-text-gray-700">
            {{ sym() }} {{ item.amount ? formatCurrency(item.amount) : '' }}
          </td>
        </template>
        <template #td-actions="{ item }">
          <td class="tw-text-right">
            <ui-button @click.native.stop="editBill(item.id)" :disabled="item.editable" class="tw-mx-1">Edit</ui-button>
            <ui-button>Sync</ui-button>
          </td>
        </template>
      </ui-data-table>
      <v-flex xs3 d-flex offset-xs9 class="tw-my-1">
        <ui-button class="tw-mx-1" @click.native.stop="firstPage" :disabled="paging.firstPageToken === undefined"
          >First</ui-button
        >
        <ui-button class="tw-mx-1" @click.native.stop="previousPage" :disabled="paging.previousPageToken === undefined"
          >Previous</ui-button
        >
        <ui-button class="tw-mx-1" @click.native.stop="nextPage" :disabled="paging.nextPageToken === undefined"
          >Next</ui-button
        >
        <ui-button class="tw-mx-1" @click.native.stop="lastPage" :disabled="paging.lastPageToken === undefined"
          >Last</ui-button
        >
      </v-flex>
    </div>
    <ui-modal v-if="showEditModal" @close="showEditModal = false">
      <edit-invoice :invoice-id="editingInvoiceId" @close="closeModal" />
    </ui-modal>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import EditInvoice from '@/components/company/EditInvoice';
import UiButton from '@/components/ui/UiButton';
import UiDatePreset from '@/components/ui/UiDatePreset.vue';
import UiModal from '@/components/ui/UiModal';
import UiSelect2 from '@/components/ui/UiSelect2';

import { getSymbolForCurrency } from '../../utils/coinUtils';
import UiDataTable from '../ui/UiDataTable.vue';
import UiLoading from '../ui/UiLoading.vue';

export default {
  computed: {
    moment() {
      return moment;
    },
    statusFilters() {
      return [
        {
          id: 'all',
          name: 'All',
        },
        {
          id: 'draft',
          name: 'Draft',
        },
        {
          id: 'sent',
          name: 'Sent',
        },
        {
          id: 'paid',
          name: 'Paid',
        },
        {
          id: 'unpaid',
          name: 'Unpaid',
        },
      ];
    },
  },
  props: ['invoiceType'],
  components: { UiDatePreset, UiDataTable, UiLoading, EditInvoice, UiModal, UiButton, UiSelect2 },
  data() {
    return {
      isLoading: 0,
      stats: {
        total: 0,
        per: 'Last 365 days',
        left: 0,
        right: 0,
        leftLabel: 'Overdue',
        rightLabel: 'Not due yet',
        leftColor: 'tw-bg-primary-300',
        rightColor: 'tw-bg-gray-300',
        leftRatio: 0,
        rightRatio: 0,
      },
      pagination: {
        sortBy: 'title',
      },
      paging: {
        nextPageToken: '',
        previousPageToken: '',
        lastPageToken: '',
        firstPageToken: '',
        totalCount: 0,
        itemsStartNumber: 0,
        itemsEndNumber: 0,
      },
      searchStatus: { id: 'unpaid', name: 'Unpaid' },
      startSEC: 0,
      endSEC: moment().unix(),
      showEditModal: false,
      editingInvoiceId: '',
      selected: [],
      headers: [
        {
          id: 'date',
          label: 'Date',
          defaultVisibility: true,
          defaultWidth: '87px',
        },
        {
          label: 'Invoice No.',
          id: 'reference',
          defaultVisibility: true,
          defaultWidth: '155px',
        },
        {
          label: 'Status',
          defaultVisibility: true,
          defaultWidth: '93px',

          id: 'status',
        },
        {
          label: 'Due Date',
          defaultVisibility: true,
          defaultWidth: '121px',

          id: 'dueDate',
        },
        {
          label: 'Source',
          defaultVisibility: true,
          defaultWidth: '107px',

          id: 'source',
        },
        {
          label: 'Customer (Matched)',
          id: 'contact',
          defaultVisibility: true,
          defaultWidth: '320px',
        },
        {
          label: this.$t('_amount'),
          defaultVisibility: true,
          id: 'amount',
          textAlignment: 'right',
          defaultWidth: '89px',
        },
        {
          label: 'Actions',
          defaultVisibility: true,
          id: 'actions',
          defaultWidth: '120px',
        },
      ],
      contacts: [],
      invoices: [],
    };
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async firstPage() {
      await this.load(this.paging.firstPageToken);
    },
    async lastPage() {
      await this.load(this.paging.lastPageToken);
    },
    async nextPage() {
      await this.load(this.paging.nextPageToken);
    },
    async previousPage() {
      await this.load(this.paging.previousPageToken);
    },
    async onDateChange(range) {
      this.startSEC = Math.floor(range[0].valueOf() / 1000);
      this.endSEC = Math.floor(range[1].valueOf() / 1000);
      await this.load();
    },
    async closeModal() {
      this.showEditModal = false;
      await this.load();
    },
    mapStatus(status) {
      switch (status) {
        case 'draft':
          return 'Draft';
        case 'open':
          return 'Open';
        case 'paid':
          return 'Paid';
        case 'voided':
          return 'Voided';
        case 'rejected':
          return 'Rejected';
        case 'accepted':
          return 'Accepted';
      }
    },
    async setStatus(status) {
      this.searchStatus = status;
      await this.load();
    },
    getSymbolForCurrency,
    buildQuery(baseUrl, pageToken) {
      const p = {
        status: this.searchStatus.id,
        invoiceType: this.invoiceType,
        startSEC: this.startSEC,
        endSEC: this.endSEC,
      };

      if (pageToken) {
        p.pageToken = pageToken;
      }

      const q = new URLSearchParams(p);

      return `${baseUrl}?${q.toString()}`;
    },
    async load(pageToken) {
      this.isLoading++;
      try {
        const baseUrl = `${process.env.VUE_APP_API_URL}v2/orgs/${this.orgId}`;
        const url = `${baseUrl}/invoices`;
        const searchUrl = this.buildQuery(url, pageToken);

        const urlStats = `${baseUrl}/invoices-stats`;
        const statsUrl = this.buildQuery(urlStats, pageToken);

        const invoices = await axios.get(searchUrl, {
          withCredentials: true,
        });

        this.invoices = invoices.data.items;
        this.paging.itemsEndNumber = invoices.data.itemsEndNumber;
        this.paging.itemsStartNumber = invoices.data.itemsStartNumber;
        this.paging.firstPageToken = invoices.data.firstPageToken;
        this.paging.nextPageToken = invoices.data.nextPageToken;
        this.paging.previousPageToken = invoices.data.previousPageToken;
        this.paging.lastPageToken = invoices.data.lastPageToken;

        const stats = await axios.get(statsUrl, {
          withCredentials: true,
        });

        this.stats.left = stats.data.overDue ?? 0;
        this.stats.right = stats.data.notDueYet ?? 0;
        this.stats.total = stats.data.total ?? 0;
        this.stats.leftRatio = (this.stats.left / (this.stats.total || 1)) * 100;
        this.stats.rightRatio = (this.stats.right / (this.stats.total || 1)) * 100;
      } catch (error) {
        console.error('error loading invoices: ', error);
      } finally {
        this.isLoading--;
      }
    },
    copyToClipboard(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        /* eslint-disable */
        return clipboardData.setData('Text', text);
        /* eslint-enable */
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        var textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand('copy'); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    editBill(invoiceId) {
      this.showEditModal = true;
      this.editingInvoiceId = invoiceId;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    refresh() {
      this.$apollo.queries.invoices.refetch();
    },
  },
};
</script>
