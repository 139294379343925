







































































































































































































































































import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Contact } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { MUT_SNACKBAR } from '@/store';

import {
  InvoicesApi,
  InvoiceSvcInvoiceDetailsDTO,
  InvoiceSvcInvoiceLine,
  InvoiceSvcInvoiceType,
} from '../../../generated/invoice-svc';
import UiButton from '../ui/UiButton.vue';
import UiCheckbox from '../ui/UiCheckbox.vue';
import UiDatePicker2 from '../ui/UiDatePicker2.vue';
import UiSelect2 from '../ui/UiSelect2.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';
import UiTooltip from '../ui/UiTooltip.vue';

type InvoiceLineUpdate = {
  categoryId?: string;
  description?: string;
};

type InvoicePatch = {
  contactId?: string;
  contactName?: string;
  contactEmail?: string;
  contactAddress1?: string;
  contactAddress2?: string;
  invoiceDate?: string;
  invoiceDueDate?: string;
  messageOnInvoice?: string;
  messageOnStatement?: string;
  tags?: string[];
  lineUpdates?: Record<number, InvoiceLineUpdate>;
};

@Component({
  components: {
    UiTextEdit,
    UiCheckbox,
    UiButton,
    UiSelect2,
    UiDatePicker2,
    UiTooltip,
  },
})
export default class EditInvoice extends BaseVue {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  public invoice: InvoiceSvcInvoiceDetailsDTO | null = null;
  public lines: InvoiceSvcInvoiceLine[] = [];
  public total = 0;

  public contactId = '';

  public updatePending = false;

  private update: InvoicePatch = {};

  public saving = false;

  public get categories() {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts() {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  onLineChange(lineNumber: number, categoryId: string) {
    if (this.update.lineUpdates === undefined) {
      this.update.lineUpdates = {};
    }

    this.update.lineUpdates[lineNumber] = {
      categoryId,
    };

    this.updatePending = true;
  }

  public get seletedContact() {
    return this.contacts.find((m: any) => m.id === this.contactId);
  }

  @Watch('contactId')
  onContactUpdated(contactId: string) {
    if (this.invoice === null) {
      throw new Error('Invoice Unexpectedly Undefined');
    }

    const contact = this.seletedContact;
    if (this.invoice.contactId !== contact.id) {
      this.update.contactId = contact.id;
      this.updatePending = true;
    }

    let applyCat;
    if (this.invoice.invoiceType === 'bill') {
      applyCat = contact.defaultExpenseCategoryId;
    } else {
      applyCat = contact.defaultRevenueCategoryId;
    }

    if (applyCat) {
      for (const l of this.lines) {
        if (l.categoryId !== applyCat) {
          l.categoryId = applyCat;
          if (this.update.lineUpdates === undefined) {
            this.update.lineUpdates = {};
          }
          this.update.lineUpdates[l.lineNumber] = {
            categoryId: applyCat,
          };
          this.updatePending = true;
        }
      }
    }
  }

  async mounted() {
    const invoiceApi = new InvoicesApi(undefined, process.env.VUE_APP_API_URL + 'v2');
    const invoicePromise = invoiceApi.getInvoice(this.orgId, this.invoiceId, { withCredentials: true });
    // const invoicePromise = axios.get(`${process.env.VUE_APP_API_URL}v2/orgs/${this.orgId}/invoices/${this.invoiceId}`, {
    //   withCredentials: true,
    // });
    const linePromise = invoiceApi.getInvoiceLines(this.orgId, this.invoiceId, undefined, undefined, {
      withCredentials: true,
    });
    // const linePromise = axios.get(
    //   `${process.env.VUE_APP_API_URL}v2/orgs/${this.orgId}/invoices/${this.invoiceId}/lines`,
    //   {
    //     withCredentials: true,
    //   }
    // );
    const invoiceResp = await invoicePromise;
    const lines = await linePromise;
    const invoice = invoiceResp.data;
    if (invoice.contactId) {
      this.contactId = invoice.contactId;
    }

    for (const l of lines.data.items) {
      this.total = this.bn(this.total).plus(this.bn(l.baseAmount)).toNumber(); // + l.baseAmount
    }

    this.invoice = invoice;
    this.lines = lines.data.items;
  }

  async save() {
    this.saving = true;
    try {
      const invoiceApi = new InvoicesApi(undefined, process.env.VUE_APP_API_URL + 'v2');
      const resp = await invoiceApi.updateInvoice(this.orgId, this.invoiceId, this.update, { withCredentials: true });
      // const url = `${process.env.VUE_APP_API_URL}v2/orgs/${this.orgId}/invoices/${this.invoiceId}`;
      // const resp = await axios.patch(url, this.update, {
      //   headers: {
      //     'content-type': 'application/json',
      //   },
      //   withCredentials: true,
      // });
      if (resp.status === 204) {
        this.showSnackbar('success', 'Invoice Saved');
      } else {
        this.showSnackbar('error', 'Problem saving invoice: ' + resp.data);
      }
      this.$emit('close');
      this.invoice = null;
    } catch (e: unknown) {
      this.showSnackbar('error', (e as Error).message);
    } finally {
      this.saving = false;
    }
  }

  showSnackbar(action: string, message: string) {
    this.$store.commit(MUT_SNACKBAR, {
      color: action,
      message,
    });
  }
}
