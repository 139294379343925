/* tslint:disable */
/* eslint-disable */
/**
 * invoice-core-svc
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface InvoiceSvcHealthDTO
 */
export interface InvoiceSvcHealthDTO {
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcHealthDTO
   */
  service: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcHealthDTO
   */
  status: InvoiceSvcHealthDTOStatusEnum;
}

export const InvoiceSvcHealthDTOStatusEnum = {
  Ok: 'OK',
  Error: 'ERROR',
} as const;

export type InvoiceSvcHealthDTOStatusEnum =
  typeof InvoiceSvcHealthDTOStatusEnum[keyof typeof InvoiceSvcHealthDTOStatusEnum];

/**
 *
 * @export
 * @interface InvoiceSvcInvoiceContact
 */
export interface InvoiceSvcInvoiceContact {
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceContact
   */
  emailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceContact
   */
  contactAddress2?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceContact
   */
  contactAddress1?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceContact
   */
  contactName?: string;
  /**
   *
   * @type {InvoiceSvcRemotePointer}
   * @memberof InvoiceSvcInvoiceContact
   */
  remotePointer?: InvoiceSvcRemotePointer;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceContact
   */
  contactId?: string;
}
/**
 *
 * @export
 * @interface InvoiceSvcInvoiceDetailsDTO
 */
export interface InvoiceSvcInvoiceDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  subsidiaryId: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  contactId?: string;
  /**
   *
   * @type {InvoiceSvcInvoiceStatus}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  invoiceStatus: InvoiceSvcInvoiceStatus;
  /**
   *
   * @type {InvoiceSvcInvoiceType}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  invoiceType: InvoiceSvcInvoiceType;
  /**
   *
   * @type {Array<string>}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  tags?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  createdAtSEC: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  createdByPrincipalId: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  updatedAtSEC: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  updatedByPrincipalId: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  approvedByPrincipalId?: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  approvedAtSEC?: number;
  /**
   *
   * @type {InvoiceSvcInvoiceHeader}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  header: InvoiceSvcInvoiceHeader;
  /**
   *
   * @type {InvoiceSvcInvoiceFooter}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  footer: InvoiceSvcInvoiceFooter;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  departmentId?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  invoiceNumber: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  poNumber?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  invoiceDate: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  invoiceDateSEC: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  dueDate: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  dueDateSEC: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  termsId?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  postingPeriodId?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  contactName?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  contactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  contactAddress1?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceDetailsDTO
   */
  contactAddress2?: string;
}
/**
 *
 * @export
 * @interface InvoiceSvcInvoiceFooter
 */
export interface InvoiceSvcInvoiceFooter {
  /**
   * Construct a type with a set of properties K of type T
   * @type {{ [key: string]: string; }}
   * @memberof InvoiceSvcInvoiceFooter
   */
  tokenSums: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceFooter
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceFooter
   */
  discountAmount?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceFooter
   */
  discountPercent?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceFooter
   */
  subTotal: number;
}
/**
 *
 * @export
 * @interface InvoiceSvcInvoiceHeader
 */
export interface InvoiceSvcInvoiceHeader {
  /**
   *
   * @type {InvoiceSvcInvoiceContact}
   * @memberof InvoiceSvcInvoiceHeader
   */
  to: InvoiceSvcInvoiceContact;
  /**
   *
   * @type {InvoiceSvcInvoiceContact}
   * @memberof InvoiceSvcInvoiceHeader
   */
  from: InvoiceSvcInvoiceContact;
}
/**
 *
 * @export
 * @interface InvoiceSvcInvoiceLine
 */
export interface InvoiceSvcInvoiceLine {
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceLine
   */
  lineNumber: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceLine
   */
  timestampSEC?: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLine
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceLine
   */
  qty: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLine
   */
  unitPrice: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLine
   */
  unitCurrencyId: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLine
   */
  unitCurrencyTicker?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLine
   */
  unitTotal: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLine
   */
  currencyTotal?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLine
   */
  exchangeRate?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLine
   */
  priceId?: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceLine
   */
  baseAmount?: number;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSvcInvoiceLine
   */
  unableToPrice?: boolean;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLine
   */
  categoryId?: string;
}
/**
 *
 * @export
 * @interface InvoiceSvcInvoiceLineUpdate
 */
export interface InvoiceSvcInvoiceLineUpdate {
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLineUpdate
   */
  categoryId?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceLineUpdate
   */
  description?: string;
}
/**
 *
 * @export
 * @interface InvoiceSvcInvoiceListDTO
 */
export interface InvoiceSvcInvoiceListDTO {
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  systemContactName?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  contactId?: string;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  editable: boolean;
  /**
   *
   * @type {InvoiceSvcInvoiceStatus}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  invoiceStatus?: InvoiceSvcInvoiceStatus;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  matched: boolean;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  dueDate: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  contactName?: string;
  /**
   *
   * @type {Array<InvoiceSvcSources>}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  synced: Array<InvoiceSvcSources>;
  /**
   *
   * @type {Array<InvoiceSvcSources>}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  sources: Array<InvoiceSvcSources>;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  invoiceNumber: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  subsidiaryId: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoiceListDTO
   */
  orgId: string;
}
/**
 *
 * @export
 * @interface InvoiceSvcInvoicePatch
 */
export interface InvoiceSvcInvoicePatch {
  /**
   * Construct a type with a set of properties K of type T
   * @type {{ [key: string]: InvoiceSvcInvoiceLineUpdate; }}
   * @memberof InvoiceSvcInvoicePatch
   */
  lineUpdates?: { [key: string]: InvoiceSvcInvoiceLineUpdate };
  /**
   *
   * @type {Array<string>}
   * @memberof InvoiceSvcInvoicePatch
   */
  tags?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoicePatch
   */
  messageOnStatement?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoicePatch
   */
  messageOnInvoice?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoicePatch
   */
  invoiceDueDate?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoicePatch
   */
  invoiceDate?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoicePatch
   */
  contactAddress2?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoicePatch
   */
  contactAddress1?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoicePatch
   */
  contactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoicePatch
   */
  contactName?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcInvoicePatch
   */
  contactId?: string;
}
/**
 *
 * @export
 * @interface InvoiceSvcInvoiceStats
 */
export interface InvoiceSvcInvoiceStats {
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceStats
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceStats
   */
  notDueYet: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceSvcInvoiceStats
   */
  overDue: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const InvoiceSvcInvoiceStatus = {
  Draft: 'draft',
  Open: 'open',
  Sent: 'sent',
  Paid: 'paid',
  Voided: 'voided',
  Rejected: 'rejected',
  Accepted: 'accepted',
} as const;

export type InvoiceSvcInvoiceStatus = typeof InvoiceSvcInvoiceStatus[keyof typeof InvoiceSvcInvoiceStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const InvoiceSvcInvoiceType = {
  Invoice: 'invoice',
  Bill: 'bill',
} as const;

export type InvoiceSvcInvoiceType = typeof InvoiceSvcInvoiceType[keyof typeof InvoiceSvcInvoiceType];

/**
 *
 * @export
 * @interface InvoiceSvcPaginatedResponseInvoiceLine
 */
export interface InvoiceSvcPaginatedResponseInvoiceLine {
  /**
   *
   * @type {Array<InvoiceSvcInvoiceLine>}
   * @memberof InvoiceSvcPaginatedResponseInvoiceLine
   */
  items: Array<InvoiceSvcInvoiceLine>;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcPaginatedResponseInvoiceLine
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface InvoiceSvcPaginatedResponseInvoiceListDTO
 */
export interface InvoiceSvcPaginatedResponseInvoiceListDTO {
  /**
   *
   * @type {Array<InvoiceSvcInvoiceListDTO>}
   * @memberof InvoiceSvcPaginatedResponseInvoiceListDTO
   */
  items: Array<InvoiceSvcInvoiceListDTO>;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcPaginatedResponseInvoiceListDTO
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface InvoiceSvcRemotePointer
 */
export interface InvoiceSvcRemotePointer {
  /**
   *
   * @type {InvoiceSvcSources}
   * @memberof InvoiceSvcRemotePointer
   */
  source: InvoiceSvcSources;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcRemotePointer
   */
  remoteId: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSvcRemotePointer
   */
  connectionId: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const InvoiceSvcSources = {
  Quickbooks: 'quickbooks',
  Netsuite: 'netsuite',
  Request: 'request',
} as const;

export type InvoiceSvcSources = typeof InvoiceSvcSources[keyof typeof InvoiceSvcSources];

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/fail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fail(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fail(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ping(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceSvcHealthDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fail(options?: any): AxiosPromise<void> {
      return localVarFp.fail(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ping(options?: any): AxiosPromise<InvoiceSvcHealthDTO> {
      return localVarFp.ping(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public fail(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .fail(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public ping(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .ping(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvoice: async (orgId: string, invoiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getInvoice', 'orgId', orgId);
      // verify required parameter 'invoiceId' is not null or undefined
      assertParamExists('getInvoice', 'invoiceId', invoiceId);
      const localVarPath = `/orgs/{orgId}/invoices/{invoiceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'invoiceId'}}`, encodeURIComponent(String(invoiceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {number} [pageSize]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvoiceLines: async (
      orgId: string,
      invoiceId: string,
      pageSize?: number,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getInvoiceLines', 'orgId', orgId);
      // verify required parameter 'invoiceId' is not null or undefined
      assertParamExists('getInvoiceLines', 'invoiceId', invoiceId);
      const localVarPath = `/orgs/{orgId}/invoices/{invoiceId}/lines`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'invoiceId'}}`, encodeURIComponent(String(invoiceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {InvoiceSvcInvoiceType} invoiceType
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStats: async (
      orgId: string,
      invoiceType: InvoiceSvcInvoiceType,
      status?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getStats', 'orgId', orgId);
      // verify required parameter 'invoiceType' is not null or undefined
      assertParamExists('getStats', 'invoiceType', invoiceType);
      const localVarPath = `/orgs/{orgId}/invoices-stats`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (invoiceType !== undefined) {
        localVarQueryParameter['invoiceType'] = invoiceType;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageSize]
     * @param {InvoiceSvcInvoiceType} [invoiceType]
     * @param {string} [status]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listInvoices: async (
      orgId: string,
      pageSize?: number,
      invoiceType?: InvoiceSvcInvoiceType,
      status?: string,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('listInvoices', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/invoices`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (invoiceType !== undefined) {
        localVarQueryParameter['invoiceType'] = invoiceType;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {InvoiceSvcInvoicePatch} invoiceSvcInvoicePatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvoice: async (
      orgId: string,
      invoiceId: string,
      invoiceSvcInvoicePatch: InvoiceSvcInvoicePatch,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateInvoice', 'orgId', orgId);
      // verify required parameter 'invoiceId' is not null or undefined
      assertParamExists('updateInvoice', 'invoiceId', invoiceId);
      // verify required parameter 'invoiceSvcInvoicePatch' is not null or undefined
      assertParamExists('updateInvoice', 'invoiceSvcInvoicePatch', invoiceSvcInvoicePatch);
      const localVarPath = `/orgs/{orgId}/invoices/{invoiceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'invoiceId'}}`, encodeURIComponent(String(invoiceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        invoiceSvcInvoicePatch,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvoice(
      orgId: string,
      invoiceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceSvcInvoiceDetailsDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoice(orgId, invoiceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {number} [pageSize]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvoiceLines(
      orgId: string,
      invoiceId: string,
      pageSize?: number,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceSvcPaginatedResponseInvoiceLine>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceLines(
        orgId,
        invoiceId,
        pageSize,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {InvoiceSvcInvoiceType} invoiceType
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStats(
      orgId: string,
      invoiceType: InvoiceSvcInvoiceType,
      status?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceSvcInvoiceStats>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStats(orgId, invoiceType, status, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageSize]
     * @param {InvoiceSvcInvoiceType} [invoiceType]
     * @param {string} [status]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listInvoices(
      orgId: string,
      pageSize?: number,
      invoiceType?: InvoiceSvcInvoiceType,
      status?: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceSvcPaginatedResponseInvoiceListDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listInvoices(
        orgId,
        pageSize,
        invoiceType,
        status,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {InvoiceSvcInvoicePatch} invoiceSvcInvoicePatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInvoice(
      orgId: string,
      invoiceId: string,
      invoiceSvcInvoicePatch: InvoiceSvcInvoicePatch,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvoice(
        orgId,
        invoiceId,
        invoiceSvcInvoicePatch,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InvoicesApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvoice(orgId: string, invoiceId: string, options?: any): AxiosPromise<InvoiceSvcInvoiceDetailsDTO> {
      return localVarFp.getInvoice(orgId, invoiceId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {number} [pageSize]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvoiceLines(
      orgId: string,
      invoiceId: string,
      pageSize?: number,
      pageToken?: string,
      options?: any
    ): AxiosPromise<InvoiceSvcPaginatedResponseInvoiceLine> {
      return localVarFp
        .getInvoiceLines(orgId, invoiceId, pageSize, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {InvoiceSvcInvoiceType} invoiceType
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStats(
      orgId: string,
      invoiceType: InvoiceSvcInvoiceType,
      status?: string,
      options?: any
    ): AxiosPromise<InvoiceSvcInvoiceStats> {
      return localVarFp.getStats(orgId, invoiceType, status, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageSize]
     * @param {InvoiceSvcInvoiceType} [invoiceType]
     * @param {string} [status]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listInvoices(
      orgId: string,
      pageSize?: number,
      invoiceType?: InvoiceSvcInvoiceType,
      status?: string,
      pageToken?: string,
      options?: any
    ): AxiosPromise<InvoiceSvcPaginatedResponseInvoiceListDTO> {
      return localVarFp
        .listInvoices(orgId, pageSize, invoiceType, status, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {InvoiceSvcInvoicePatch} invoiceSvcInvoicePatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvoice(
      orgId: string,
      invoiceId: string,
      invoiceSvcInvoicePatch: InvoiceSvcInvoicePatch,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateInvoice(orgId, invoiceId, invoiceSvcInvoicePatch, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {string} invoiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvoicesApi
   */
  public getInvoice(orgId: string, invoiceId: string, options?: AxiosRequestConfig) {
    return InvoicesApiFp(this.configuration)
      .getInvoice(orgId, invoiceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} invoiceId
   * @param {number} [pageSize]
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvoicesApi
   */
  public getInvoiceLines(
    orgId: string,
    invoiceId: string,
    pageSize?: number,
    pageToken?: string,
    options?: AxiosRequestConfig
  ) {
    return InvoicesApiFp(this.configuration)
      .getInvoiceLines(orgId, invoiceId, pageSize, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {InvoiceSvcInvoiceType} invoiceType
   * @param {string} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvoicesApi
   */
  public getStats(orgId: string, invoiceType: InvoiceSvcInvoiceType, status?: string, options?: AxiosRequestConfig) {
    return InvoicesApiFp(this.configuration)
      .getStats(orgId, invoiceType, status, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {number} [pageSize]
   * @param {InvoiceSvcInvoiceType} [invoiceType]
   * @param {string} [status]
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvoicesApi
   */
  public listInvoices(
    orgId: string,
    pageSize?: number,
    invoiceType?: InvoiceSvcInvoiceType,
    status?: string,
    pageToken?: string,
    options?: AxiosRequestConfig
  ) {
    return InvoicesApiFp(this.configuration)
      .listInvoices(orgId, pageSize, invoiceType, status, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} invoiceId
   * @param {InvoiceSvcInvoicePatch} invoiceSvcInvoicePatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvoicesApi
   */
  public updateInvoice(
    orgId: string,
    invoiceId: string,
    invoiceSvcInvoicePatch: InvoiceSvcInvoicePatch,
    options?: AxiosRequestConfig
  ) {
    return InvoicesApiFp(this.configuration)
      .updateInvoice(orgId, invoiceId, invoiceSvcInvoicePatch, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
